import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import RightArrow from "../../assets/Icons/go.svg";
import Clm2Img from "../../assets/Icons/mail.svg";
import Clm1Img from "../../assets/Icons/phone.svg";
import BackArrowIcon from "../../assets/Images/accordian.svg";
import BannerBgImage from "../../assets/Images/ArticleWhitePaper/Articles_banner.png";
import ogimage from "../../assets/Images/ogImages/article@2x.png";
import Button from "../../components/Button";
import Layout from "../../components/layouts/layout";
import DataService from "../../services/api/data_services";
import { BASE_SITE_URL } from "../../shared/constants";
import ArticleTag from "../../templates/ArticleTag";
import Box from "../../templates/Box";
import ImageBanner from "../../templates/ImageBanner";
import Miniview from "../../templates/MiniView";
import TwoColumnContactInfo from "../../templates/TwoColumnContactInfo";

const ArticleList = (props) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  const [articleData, setArticleData] = useState([]);
  const [articleRawData, setArticleRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const [articleTag, setArticleTag] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const urlParams =
    typeof window !== `undefined` &&
    new URLSearchParams(window.location.search);
  const myParam = typeof window !== `undefined` && urlParams.get("tag");

  useEffect(() => {
    DataService.getData(
      `/admin/api/article-tag?limit=${limit}&type=Article&article_type=${
        myParam || "all"
      }`
    ).then((response) => {
      setArticleTag(response.all_tags);
      setSelectedTag(response.selected_tag);
      setArticleData(response.data);
      setArticleRawData(response);
    });
  }, [page, myParam, limit]);

  const toggleHover = () => {
    setIsHover(!isHover);
  };

  /**
   * TwoColumnContactInfo
   */
  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Box component
   */

  const BoxData = {
    boxBtn1Width: "230px",
    boxBtn1Height: "46px",
    boxBtn1variant: "contained",
    boxBtn1color: "primary",
    boxBtn1label: "CAREER",
    tip: "CAREER",
    tooltip: "yes",
    boxBtn1Title: "Join our team",
    Clm2SubTitle: "careers@digiryte.com",
    boxBtn1SubTitle:
      "If you're a designer or a developer who loves to challenge conventions, make the ryte move!",
    boxBtn2Width: "230px",
    boxBtn2Height: "46px",
    boxBtn2variant: "contained",
    boxBtn2color: "primary",
    boxBtn2label: "GET IN TOUCH",
    boxBtn2Title: "Let’s talk",
    tip1: "GET IN TOUCH",
    boxBtn2SubTitle:
      "Have you got an exciting project that you'd like to discuss?",
    route1: "/contact-us",
    route2: "/contact-us",
  };

  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: "Articles",
    subtitleclassname: "",
    bnrImgSubTitle: "All Resources",
    bnrImgClassName: "insightsbannerimg",
    servicesColumn1: {
      data: [
        {
          service: "Articles",
          link: "/insights/articles",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "White Papers",
          link: "/insights/white-papers",
        },
      ],
    },
    // servicesColumn3: {
    //   data: [
    //     {
    //       service: "Podcast",
    //       link: "/insights/podcast",
    //     },
    //   ],
    // },
  };

  return (
    <Layout
      seo={{
        title: "Insights | Articles |",
        ogtitle: "Insights | Articles |",
        description: "Our hub of knowledge to help your business succeed",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/insights/articles`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-70 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="Insights"
          routepath="/insights"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Insights
        </Typography>
      </div>
      <div className="mt-30 body-container">
        {myParam === null ? (
          ""
        ) : (
          <ArticleTag
            selectedTag={selectedTag}
            articleTag={articleTag}
            articleTagApi={articleTag}
          />
        )}
      </div>
      {articleData && articleData.length > 0 && (
        <React.Fragment>
          <div className="mt-30 body-container ttc">
            <div className="title-header">
              <span className="red-line3letter"> Art</span>icles
            </div>
          </div>
          <div className="mt-50">
            <Miniview miniviewData={articleData} />
          </div>
        </React.Fragment>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setPage(page + 1);
              setLimit(limit + 6);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="LOAD MORE"
              tip="LOAD MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-70"></div>
      <Box BoxData={BoxData} />
      <div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default ArticleList;
