import React from "react";
import "./ArticleTag.scss";
import { Link } from "gatsby";

const ArticleTag = (props) => {
  return (
    <div>
      {props.articleTag &&
        props.articleTag.map((item, index) => (
          <Link
            to={`/insights/articles?tag=${item.slug}`}
            className="services-link"
          >
            <div
              className={
                item.type === props.selectedTag
                  ? "selected-container"
                  : "tag-container"
              }
            >
              # {item.type}
            </div>
          </Link>
        ))}
    </div>
  );
};

export default ArticleTag;
